import { Component, OnInit } from '@angular/core'

@Component({
    selector: 'app-what-is-discgolf',
    templateUrl: './what-is-discgolf.component.html',
    styleUrls: ['./what-is-discgolf.component.scss'],
})
export class WhatIsDiscgolfComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
