import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { NavigationComponent } from './navigation/navigation.component'
import { HomeComponent } from './sites/home/home.component'
import { CalendarComponent } from './sites/calendar/calendar.component'
import { ImageDividerComponent } from './layout/image-divider/image-divider.component'
import { HeaderComponent } from './header/header.component'
import { WhatIsDiscgolfComponent } from './sites/what-is-discgolf/what-is-discgolf.component'
import { ParagraphsComponent } from './layout/paragraphs/paragraphs.component'
import { UeberUnsComponent } from './sites/ueber-uns/ueber-uns.component'
import { ContactComponent } from './sites/contact/contact.component'

@NgModule({
    declarations: [AppComponent, NavigationComponent, HomeComponent, CalendarComponent, ImageDividerComponent, HeaderComponent, WhatIsDiscgolfComponent, ParagraphsComponent, UeberUnsComponent, ContactComponent],
    imports: [BrowserModule, AppRoutingModule],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
