import { Component } from '@angular/core'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    moveLogoDown = false

    navOpened(): void {
        this.moveLogoDown = false
    }

    navClosed(): void {
        this.moveLogoDown = true
    }
}
