import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HomeComponent } from './sites/home/home.component'
import { CalendarComponent } from './sites/calendar/calendar.component'
import { WhatIsDiscgolfComponent } from './sites/what-is-discgolf/what-is-discgolf.component'
import { UeberUnsComponent } from './sites/ueber-uns/ueber-uns.component'
import { ContactComponent } from './sites/contact/contact.component'

const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'terminkalender', component: CalendarComponent },
    { path: 'ueber-uns', component: UeberUnsComponent },
    { path: 'was-ist-discgolf', component: WhatIsDiscgolfComponent },
    { path: 'kontakt', component: ContactComponent },
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
