<app-paragraphs title="Über uns">
    <div class="item">
        <p>
            Wir sind ein im Dezember 2020 gegründeter Disc Golf Verein und nennen uns Foggy Baskets Aargau. <br /><br />Der Wunsch nach der Verbreitung des Sports und nach einer Trainingsanlage bzw. nach einem eigenen Kurs hat uns
            angetrieben, den ersten Verein im Kanton Aargau zu gründen. Privat kennen sich die Mitglieder bereits seit der Primarschule. Die meisten von uns spielten jahrelang im selben Verein Fussball und seit mehr als 3 Jahren üben wir
            gemeinsam regelmässig den Sport Disc Golf aus.
        </p>
    </div>
    <div class="item">
        <p>
            Einige unserer Mitglieder nehmen bereits seit einiger Zeit an der offiziellen «Swisstour» teil – eine Disc Golf Turnierserie von Genf, über Zürich, bis Kreuzlingen. <br /><br />Unser Verein ist an die «Swiss Disc Golf
            Association» angeschlossen. Somit sind wir der «Swiss Disc Sports Association» angehörig, welche ein Teil von Swiss Olympics ist.
        </p>
    </div>
</app-paragraphs>

<app-image-divider path="/assets/dividers/gutschi_opt.jpg" backgroundPosition="center 30%"></app-image-divider>

<app-paragraphs title="" [dark]="true">
    <div class="item">
        <p></p>
    </div>
    <div class="item">
        <p></p>
    </div>
</app-paragraphs>
