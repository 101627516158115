import { Component, OnInit } from '@angular/core'

@Component({
    selector: 'app-ueber-uns',
    templateUrl: './ueber-uns.component.html',
    styleUrls: ['./ueber-uns.component.scss'],
})
export class UeberUnsComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
