<app-paragraphs title="Aargauer Disc Golf Verein">
    <div class="item">
        <p>Willkommen auf der Website der FOGGY BASKETS AARGAU, dem ersten Disc Golf Verein im Kanton Aargau.</p>
    </div>
    <div class="item">
        <p></p>
    </div>
</app-paragraphs>

<app-image-divider path="/assets/dividers/konsti_schuel_opt.jpg"></app-image-divider>

<app-paragraphs title="Juni 2023 - Die ersten Körbe stehen 💪">
    <div class="item">
        <p>
            Dank Eurer zahlreichen Unterstützung, sowie der Mithilfe der Gemeinde Auenstein, konnten wir das Konzept "Disc Golf in Auenstein" erfolgreich umsetzten. <br /><br />
            Mit den vier Körben, welche installiert wurden, haben wir einen kleinen, aber attraktiven 9-Loch Kurs für Anfänger und Fortgeschrittene aufgebaut.<br /><br />Das Kurslayout, sowie weitere Informationen
            <a href="https://udisc.com/courses/schulhaus-auenstein-BBAn" target="_blank" src="">findet Ihr bei UDisc.</a>
        </p>
    </div>
    <div class="item">
        <a href="https://udisc.com/courses/schulhaus-auenstein-BBAn" target="_blank" src=""><img style="padding: 3em 0" src="/assets/others/kurs_auenstein.png" alt="Disc Golf Kurs Auenstein" /></a><br />
    </div>
</app-paragraphs>

<app-image-divider path="/assets/dividers/baskets_neuchatel_opt.jpg"></app-image-divider>

<app-paragraphs title="Feb. 2023 - Disc Golf kommt nach Auenstein 🎉">
    <div class="item">
        <p>Wir freuen uns sehr, dass wir mit unserem Konzept "Disc Golf in Auenstein" die Gemeinde überzeugen konnten.<br /><br />Schon bald werden 4 Disc Golf Körbe zur allgemeinen Nutzung auf dem Schulhausareal in Auenstein aufgebaut!</p>
        <img style="padding: 1em 0" src="/assets/others/auenstein-wappen.png" alt="Wappen von Auenstein" width="100px" />
        <p style="font-weight: bold; font-size: 1.2em">Nun brauchen wir Eure Unterstützung!</p>
        <p>Eine Spende in beliebiger Höhe bringt die Finanzierung des Projektes voran und bedeutet uns sehr viel!</p>
    </div>
    <div class="item">
        <p>Unser Konto (IBAN)</p>
        <p style="font-weight: bold">CH84 8080 8001 0836 0470 1 <br />Foggy Baskets Aargau, 5000 Aarau</p>
        <div style="text-align: left; padding-top: 1em">
            <img src="/assets/others/twint-barcode.jpg" alt="TWINT Barcode zum Spenden" style="width: 100%; max-width: 600px" />
        </div>
    </div>
</app-paragraphs>

<app-paragraphs title="Vielen Dank!" size="h2">
    <div class="item">
        <a href="/assets/others/Konzept Disc Golf Körbe Schule Auenstein.pdf" target="_blank"><img src="/assets/icons/pdf.svg" width="60px" style="padding-top: 6px" alt="PDF Icon" /></a><br />
        <a href="/assets/others/Konzept Disc Golf Körbe Schule Auenstein.pdf" target="_blank">Download Konzept</a>
    </div>
</app-paragraphs>

<app-image-divider path="/assets/dividers/konsti_opt.jpg"></app-image-divider>

<app-paragraphs title="Disc Golf - Ein Sport für Gross und Klein" [dark]="true">
    <div class="item">
        <p>Wir freuen uns, Euch auf unserer Website begrüssen zu dürfen und Euch den Sport Disc Golf, sowie unsere Aktivitäten etwas näher zu bringen.</p>
    </div>
</app-paragraphs>
