import { Component, OnInit } from '@angular/core'

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
    zoomIn = false

    constructor() {}

    ngOnInit(): void {}

    onIntersection($event: IntersectionObserverEntry[]): void {
        console.log('now')
        this.zoomIn = true
    }
}
