import { Component, Input, OnInit } from '@angular/core'

@Component({
    selector: 'app-paragraphs',
    templateUrl: './paragraphs.component.html',
    styleUrls: ['./paragraphs.component.scss'],
})
export class ParagraphsComponent implements OnInit {
    @Input() title = ''
    @Input() dark = false
    @Input() size: 'h1' | 'h2' = 'h1'

    constructor() {}

    ngOnInit(): void {}
}
