<div class="container">
    <h2>Terminkalender</h2>
</div>
<div class="container">
    <iframe
        src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Europe%2FZurich&src=Y19uODlpZWQ5bW9nZmJraGw3MDRkYWN0bHJvb0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%234285F4"
        frameborder="0"
        scrolling="no"
    >
    </iframe>
</div>

<app-image-divider path="/assets/dividers/konsti_schuel_opt.jpg"></app-image-divider>
<div class="container-gray"></div>
