<app-paragraphs title="Was ist Disc Golf?">
    <div class="item">
        <p>
            Disc Golf ist vom Regelwerk sehr ähnlich wie Golf oder Minigolf. Der Hauptunterschied besteht darin, dass man mit Discs spielt, anstelle von einem Golfball. Eine Disc ist ähnlich wie ein herkömmlicher Frisbee, kann jedoch mit
            der richtigen Technik, weiter geworfen werden.<br /><br />
            Das Ziel des Sports besteht darin, die Disc mit so wenig Würfen wie möglich ins Ziel, den Korb, zu werfen.
        </p>
    </div>
    <!--    <div class="item">-->
    <!--        <p></p>-->
    <!--    </div>-->
</app-paragraphs>

<app-image-divider path="/assets/dividers/pots_opt.jpg" backgroundPosition="center 40%"></app-image-divider>

<app-paragraphs title="Disc Golf auf Youtube" [dark]="true">
    <div class="item">
        <iframe src="https://www.youtube.com/embed/o9LWgNvp2Fw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="item">
        <iframe src="https://www.youtube.com/embed/352G0B4OClU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
</app-paragraphs>
