import { Component, EventEmitter, OnInit, Output } from '@angular/core'

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
    @Output() navOpened: EventEmitter<any> = new EventEmitter<any>()
    @Output() navClosed: EventEmitter<any> = new EventEmitter<any>()

    isNavOpen = false
    menuItems = [
        { title: 'Home', path: '/home' },
        { title: 'Über uns', path: '/ueber-uns' },
        { title: 'Terminkalender', path: '/terminkalender' },
        { title: 'Was ist Disc Golf?', path: '/was-ist-discgolf' },
        { title: 'Kontakt', path: '/kontakt' },
    ]

    toggleNav(): void {
        this.isNavOpen = !this.isNavOpen
        if (this.isNavOpen) {
            this.navOpened.emit()
        } else {
            this.navClosed.emit()
        }
    }

    ngOnInit(): void {}
}
