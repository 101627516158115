<span class="menu-icon fade-in" [class.menu-icon-outlined]="navClosed" (click)="toggleNav()"><ng-container *ngIf="isNavOpen; else isClosed">&#x2715;</ng-container><ng-template #isClosed>☰</ng-template></span>

<div class="navigation overlay" [class.navigation-show]="isNavOpen">
    <div class="logo"></div>

    <div class="overlay-content">
        <a *ngFor="let item of menuItems" [routerLink]="item.path" style="text-transform: uppercase" routerLinkActive="active-item" (click)="toggleNav()">{{ item.title }}</a>
    </div>
</div>

<div class="robot-links">
    <a *ngFor="let item of menuItems" [routerLink]="item.path" routerLinkActive="active-item">{{ item.title }}</a>
</div>
