<app-paragraphs title="Kontakt">
    <div class="item">
        <p><strong>E-Mail</strong></p>
        <p><a href="mailto:foggybaskets@gmail.com">foggybaskets&#64;gmail.com</a></p>
    </div>
    <div class="item">
        <p><strong>Konto (IBAN)</strong></p>
        <p>CH84 8080 8001 0836 0470 1 <br />Foggy Baskets Aargau, 5000 Aarau</p>
    </div>
</app-paragraphs>

<app-image-divider path="/assets/dividers/baskets_neuchatel_opt.jpg"></app-image-divider>
