import { Component, Input, OnInit } from '@angular/core'

@Component({
    selector: 'app-image-divider',
    templateUrl: './image-divider.component.html',
    styleUrls: ['./image-divider.component.scss'],
})
export class ImageDividerComponent implements OnInit {
    @Input() path = ''
    @Input() backgroundPosition = 'center 25%'

    constructor() {}

    ngOnInit(): void {}
}
